import Vue from 'vue';
import App from './App.vue';
import 'normalize.css';
import '@fontsource/roboto';
import VBodyScrollLock from 'v-body-scroll-lock';

Vue.config.productionTip = false;
Vue.use(VBodyScrollLock);

new Vue({
	render: (h) => h(App),
}).$mount('#chat');
