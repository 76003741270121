<template>
  <div id="v-chat" :class="{'v-chat-opened': isOpened}" class="v-chat-style-mobile">
    <button class="v-chat-open v-hide-mobile" v-show="!isOpened" @click="changeWindow">
      Виртуальный помощник
    </button>
    <button class="v-chat-open-mobile v-show-mobile" v-show="!isOpened" @click="changeWindow">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.17644 13.1497C9.19833 13.1497 10.0267 13.9781 10.0267 15C10.0267 16.0219 9.1983 16.8503 8.17644 16.8503C7.15462 16.8503 6.32619 16.0219 6.32619 15C6.32619 13.9781 7.15462 13.1497 8.17644 13.1497ZM15 0C23.2843 0 30 6.71571 30 15C30 23.2843 23.2843 30 15 30C12.5559 30 10.2485 29.4151 8.20963 28.3781L0 30L1.62189 21.7904C0.584907 19.7516 0 17.4441 0 15C0 6.71571 6.71571 0 15 0V0ZM4.36527 25.6347L8.70881 24.7766L9.76747 25.3151C11.3929 26.1417 13.1769 26.5635 15 26.5635C21.3874 26.5635 26.5635 21.3874 26.5635 15C26.5635 8.61256 21.3874 3.43648 15 3.43648C8.61253 3.43648 3.43645 8.61256 3.43645 15C3.43645 16.823 3.85819 18.6071 4.68489 20.2324L5.22338 21.2911L4.36525 25.6347H4.36527ZM21.8236 13.1497C22.8454 13.1497 23.6738 13.9781 23.6738 15C23.6738 16.0219 22.8454 16.8503 21.8236 16.8503C20.8017 16.8503 19.9733 16.0219 19.9733 15C19.9733 13.9781 20.8017 13.1497 21.8236 13.1497V13.1497ZM15 13.1497C16.0219 13.1497 16.8503 13.9781 16.8503 15C16.8503 16.0219 16.0219 16.8503 15 16.8503C13.9781 16.8503 13.1497 16.0219 13.1497 15C13.1497 13.9781 13.9781 13.1497 15 13.1497Z" fill="white"/>
      </svg>
    </button>
    <div class="v-chat-window" v-show="isOpened">
      <div class="v-chat-window-header">
        <button class="v-chat-button-close" @click="changeWindow">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.68408" y="16.0001" width="2.38183" height="20.2456" rx="1" transform="rotate(-135 1.68408 16.0001)" fill="#84A8FF"/>
            <rect y="1.68427" width="2.38183" height="20.2456" rx="1" transform="rotate(-45 0 1.68427)" fill="#84A8FF"/>
          </svg>
        </button>
        <div class="v-chat-title">
          {{ getCurrentDay() }}
        </div>
        <div class="v-chat-button-find"></div>
      </div>
      <div class="v-chat-window-body" v-body-scroll-lock="isOpened && isMobile">
        
        <div class="v-chat-content">
          <div class="v-chat-item" v-for="(item, idx) in history" :key="idx" :class="[{'v-client': item.who == 'client'}, {'v-bot': item.who == 'bot'}]">
            <div class="v-chat-item-message" v-if="item.who === 'client'">
              {{ item.text }}
            </div>
            <div class="v-chat-item-message" v-if="item.who === 'bot'" :class="{'v-chat-item-bot-msg': item.type === 'msg'}">
              <ul class="v-bot-list" v-if="item.type === 'list'">
                <li class="v-bot-list-item" v-for="(link, index) in item.text" :key="index">
                  <a :href="link.link" class="v-bot-list-item-link" target="_blank">{{ link.text }}</a>
                </li>
              </ul>
              <span v-if="item.type === 'msg'">
                {{ item.text }}
              </span>
            </div>
            <div class="v-chat-item-information">
              <span class="v-chat-item-information-time">{{ item.time }}</span>
              <span class="v-chat-item-information-sended" v-if="item.who === 'client' && item.sended">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="6.85938" y="15.9089" width="1.98867" height="12.9264" rx="0.994337" transform="rotate(-135 6.85938 15.9089)" fill="#A7A7A7"/>
                  <rect y="9.23148" width="1.98867" height="8.94903" rx="0.994336" transform="rotate(-45 0 9.23148)" fill="#A7A7A7"/>
                </svg>
              </span>
            </div>
          </div>
        </div>

      </div>
      <div class="v-chat-window-footer">
        <form class="v-chat-form" @submit.prevent="sendQuery">
          <input type="text" class="v-chat-input" placeholder="Введите сообщение..." autocomplete="off" v-model="query">
          <button type="submit" class="v-chat-send">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="1.47025" height="9.55664" rx="0.735126" transform="matrix(-0.714543 0.699591 -0.714543 -0.699592 15.0566 7.37146)" fill="white"/>
              <rect width="1.47025" height="9.55664" rx="0.735127" transform="matrix(0.714543 0.699592 -0.714543 0.699591 14.0059 6.34283)" fill="white"/>
              <rect x="0.942383" y="7.95892" width="1.45463" height="12.6286" rx="0.727314" transform="rotate(-90 0.942383 7.95892)" fill="white"/>
            </svg>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import data from '@/data/messages.json';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'App',
  data () {
    return {
      isOpened: false,
      history: [],
      query: null,
      isMobile: false,
    }
  },
  mounted() {
    this.scrollToElement();
    this.updateHistory();
    if( screen.width <= 425 ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    let limit = 24 * 3600 * 1000;
    let localStorageInitTime = localStorage.getItem('vChatLsLifeTime');
    if (localStorageInitTime === null) {
      localStorage.setItem('vChatLsLifeTime', +new Date());
    } else if(+new Date() - localStorageInitTime > limit) {
      localStorage.removeItem('vChatHistory');
      localStorage.removeItem('vChatLsLifeTime');
      localStorage.setItem('vChatHistory', JSON.stringify(data));
      localStorage.setItem('vChatLsLifeTime', +new Date());
    }
  },
  methods: {
    changeWindow: function () {
      this.isOpened = !this.isOpened;
      this.scrollToElement();
    },
    scrollToElement: function () {
      const container = this.$el.querySelector('.v-chat-window-body');
      if (container) {
        setTimeout(function() {
          container.scrollTop = container.scrollHeight;
        }, 0);
      }
    },
    updateHistory: function () {
      if (this.getHistory() === null) {
        localStorage.setItem('vChatHistory', JSON.stringify(data));
      }

      this.history = this.getHistory();
    },
    getHistory: function () {
      return JSON.parse(localStorage.getItem('vChatHistory'));
    },
    addMessage: function(message) {
      let history = this.getHistory();
      let _id = uuidv4();
      let currentMessage = {
        "id": _id,
        "who": message.who,
        "type": message.type,
        "text": message.text,
        "time": this.getTime(),
        "sended": false,
      }
      
      history.push(currentMessage);
      localStorage.setItem('vChatHistory', JSON.stringify(history));

      this.updateHistory();
      this.scrollToElement();
      return _id;
    },
    getCurrentDay: function () {
      let today = new Date();
      let month = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      return today.getDate() + ' ' + month[today.getMonth()];
    },
    getTime: function () {
      let today = new Date();
      return today.getHours() + ":" + (today.getMinutes()<10?'0':'') + today.getMinutes();
    },
    changeStatus: function (_id) {
      let history = this.getHistory();
      let searchObj = history.find(elem => elem.id === _id)
      searchObj.sended = true;
      if (localStorage.setItem('vChatHistory', JSON.stringify(history))) {
        return true;
      }
      return false;
    },
    sendQuery: function () {
      let value = this.removeSpecial(this.query)
      let message = {
        "who": "client",
        "type": "msg",
        "text": value,
      }
      let messageId = this.addMessage(message);
      
      fetch('?action=chat&q=' + value, {
        method: 'get'
      })
        .then(res => {
          this.changeStatus(messageId);
          this.updateHistory();
          return res.json()
        })
        .then(data => {
          if (data.status === true) {
            let message = data.data;
            let response = {
              "who": "bot",
              "type": "list",
              "text": message
            }
            this.addMessage(response);
          } else {
            let message = data.msg;
            let response = {
              "who": "bot",
              "type": "msg",
              "text": message
            }
            this.addMessage(response);
            console.error(data)
          }
        })
        .catch(err => console.error(err))

      this.query = null;
    },
    removeSpecial: function (str) {
      let lower = str.toLowerCase();
      let upper = str.toUpperCase();

      let res = "";
      for(var i=0; i<lower.length; ++i) {
        if(this.isNumber(str[i]) || lower[i] != upper[i] || lower[i].trim() === '')
          res += str[i];
      }
      return res;
    },
    isNumber: function (num) {
      if(num) {
        var reg = new RegExp('[0-9]+$');
        return reg.test(num);
      }
      return false;
    }
  },
}
</script>

<style lang="scss">
#v-chat * {
  box-sizing: border-box;
}
#v-chat {
  font-family: 'Roboto', sans-serif;
  position: fixed;
  bottom: 0;
  right: 50px;
  width: 350px;
  min-height: 65px;
  z-index: 999;
}
.v-chat-open {
  display: flex;
  background: linear-gradient(180deg, #093479 0%, #2C5493 100%);
  border-radius: 7px 7px 0px 0px;
  height: 65px;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;

}
.v-chat-window {
  max-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F3F3F3;
  border-radius: 7px 7px 0px 0px;
  overflow: hidden;
}
.v-chat-window-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, #093479 0%, #2C5493 100%);
  height: 65px;
  padding: 16px;
  // border-radius: 10px 10px 0 0;
}
.v-chat-window-body {
  display: flex;
  height: calc(100% - 65px - 50px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.v-chat-window-footer {
  display: flex;
  padding: 16px;
}
.v-chat-button-close {
  padding: 8px;
  border: 0;
  cursor: pointer;
  background: none;
  display: flex;
}
.v-chat-button-find {
  appearance: none;
  opacity: 0;
  width: 32px;
}
.v-chat-title {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.v-chat-form {
  width: 100%;
  position: relative;
}
.v-chat-send {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #264796;
  border-radius: 50px;
  border: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.v-chat-input {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 30px;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 16px;
  width: 100%;
  height: 42px;
  &::placeholder {
    color: #A7A7A7;
  }
}
.v-chat-content {
  width: 100%;
  padding: 16px;
}
.v-chat-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.v-client {
  align-items: flex-end;

  .v-chat-item-message {
    background: #264796;
    border-radius: 10px 10px 0 10px;
  }
}
.v-bot {
  .v-chat-item-message {
    padding: 0;
  }
  .v-chat-item-bot-msg {
    background: #E1E1E1;
    border-radius: 10px 10px 10px 0px;
    color: #000;
    padding: 10px 25px;
  }
}
.v-bot-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.v-bot-list-item {
  margin-bottom: 20px;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
}
.v-bot-list-item-link {
  background: #FFFFFF;
  border: 2px solid #264796;
  border-radius: 20px;
  font-size: 15px;
  line-height: 18px;
  color: #264796;
  padding: 10px 24px;
  text-decoration: none;
  transition: all .2s ease-out;
  &:hover {
    background: #264796;
    color: #fff;
  }
}
.v-chat-item-message {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 10px 25px;
  max-width: 90%;
}
.v-chat-item-information {
  margin-top: 4px;
}
.v-chat-item-information-time {
  font-size: 12px;
  line-height: 14px;
  color: #A7A7A7;
}
.v-chat-item-information-sended {
  margin-left: 8px;
}
.v-show-mobile {
  display: none;
}
.v-chat-opened {
  max-height: 600px;
  height: 100%;
}
.v-chat-open-mobile {
  padding: 15px;
  cursor: pointer;
  margin: 0;
  border: 0;
  background: #264796;
  box-shadow: 5px 15px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  width: 60px;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .v-hide-mobile {
    display: none;
  }
  .v-show-mobile {
    display: block;
  }
  #v-chat.v-chat-style-mobile {
    width: 60px;
    height: 60px;
    bottom: 20px;
  }
  #v-chat.v-chat-style-mobile.v-chat-opened {
    max-height: 600px;
    height: 100%;
    bottom: 0;
    max-width: 320px;
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  #v-chat.v-chat-style-mobile.v-chat-opened {
    max-width: none;
    // width: 100%;
    max-height: none;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    // overflow: hidden;
  }
  #v-chat {
    right: 20px;
    min-height: auto;
  }
  .v-chat-window {
    border-radius: 0;
    max-height: none;
  }
  .v-chat-input {
    outline: none;
    -webkit-appearance: none;
  }
  .v-chat-window-header {
    border-radius: 0;
  }
}
</style>
